import React from "react";
import { ToastContainer } from "react-toastify";
import InputLayout from "./InputLayout";

function EarlierCourt({
  // data = {},
  isEditable,
  // setIsEditable,
  // setUpdatedData = () => {},
  earlierCourtDetails = {},
  setEarlierCourtDetails = () => {},
}) {
  // const [petitionerData, setPetitionerData] = useState({
  //   AdvocateName: data?.AdvocateName,
  //   AdvocateRegistrationNumber: data?.AdvocateRegistrationNumber,
  //   AdvocateMobile: data?.AdvocateMobile,
  //   AdvocateEMail: data?.AdvocateEMail,
  //   ImpugnedFinalOrderDate: data?.ImpugnedFinalOrderDate,
  //   ImpugnedInterimOrderDate: data?.ImpugnedInterimOrderDate,
  //   ImpugnedOrderCourt: data?.ImpugnedOrderCourt,
  //   OrderChallenged: data?.OrderChallenged,
  //   ImpugnedOrderJudges: data?.ImpugnedOrderJudges,
  // });

  // useEffect(() => {
  //   setPetitionerData({
  //     AdvocateName: data?.AdvocateName,
  //     AdvocateRegistrationNumber: data?.AdvocateRegistrationNumber,
  //     AdvocateMobile: data?.AdvocateMobile,
  //     AdvocateEMail: data?.AdvocateEMail,
  //     ImpugnedFinalOrderDate: data?.ImpugnedFinalOrderDate,
  //     ImpugnedInterimOrderDate: data?.ImpugnedInterimOrderDate,
  //     ImpugnedOrderCourt: data?.ImpugnedOrderCourt,
  //     OrderChallenged: data?.OrderChallenged,
  //     ImpugnedOrderJudges: data?.ImpugnedOrderJudges,
  //   });
  // }, [data]);

  // const handleEdit = (e) => {
  //   e.preventDefault();
  //   setIsEditable(true);
  // };
  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   setIsEditable(false);
  // };

  // const handleSaveDraft = (e) => {
  //   e.preventDefault();
  //   setUpdatedData((prev) => ({
  //     ...prev,
  //     AdvocateName: petitionerData?.AdvocateName,
  //     AdvocateRegistrationNumber: petitionerData?.AdvocateRegistrationNumber,
  //     AdvocateMobile: petitionerData?.AdvocateMobile,
  //     AdvocateEMail: petitionerData?.AdvocateEMail,
  //     ImpugnedFinalOrderDate: petitionerData?.ImpugnedFinalOrderDate,
  //     ImpugnedInterimOrderDate: petitionerData?.ImpugnedInterimOrderDate,
  //     ImpugnedOrderCourt: petitionerData?.ImpugnedOrderCourt,
  //     ImpugnedOrderJudges: petitionerData?.ImpugnedOrderJudges,
  //     OrderChallenged: petitionerData?.OrderChallenged,
  //     RespondentState: petitionerData?.RespondentState,
  //     RespondentPincode: petitionerData?.RespondentPincode,
  //     RespondentAddress: petitionerData?.RespondentAddress,
  //   }));
  //   setIsEditable(false);
  // };
  // const { handleEfilingUpdate } = useUpdateEfiling({ data });

  return (
    <div>
      <div className="flex relative justify-center">
        <form
          className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
          // onSubmit={handleSaveDraft}
        >
          <div>
            <div className=" text-[#192839]  text-base font-semibold">
              Earlier Courts
            </div>
            {/* {authRole !== "User" ? (
              <div>
                <button
                  onClick={() => setShowModal(true)}
                  className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                >
                  Save and Update
                </button>
              </div>
            ) : null} */}
            {/* {authRole !== "User" ? (
              <div>
                {isEditable ? (
                  <button
                    onClick={handleCancel}
                    className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    onClick={handleEdit}
                    className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                  >
                    Edit
                  </button>
                )}
              </div>
            ) : null} */}
          </div>
          <hr className="my-4"></hr>

          <InputLayout
            isEditable={isEditable}
            petitionerData={earlierCourtDetails}
            setPetitionerData={setEarlierCourtDetails}
          />

          {/* <div className="flex justify-center gap-4">
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                setActiveTab("Respondent");
              }}
            >
              Previous
            </button>
            {authRole !== "User" ? (
              <button
                className="bg-green-700 hover:bg-green-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Save Draft
              </button>
            ) : null}
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                setActiveTab("View_Document");
              }}
            >
              Next
            </button>
          </div> */}
        </form>
        {/* {showMoadl ? (
          <ConfirmModal
            setShowModal={setShowModal}
            handleEfilingUpdate={handleEfilingUpdate}
            id={id}
          />
        ) : null} */}
        <ToastContainer />
      </div>
    </div>
  );
}

export default EarlierCourt;
