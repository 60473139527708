// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import MetaDataField from "./MetaDataField";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import DoclabelDefect from "./DoclabelDefect";

// const OPTIONS = [
// { label: "Unsigned Box", value: "Unsigned Box" },
// { label: "Blur", value: "Blur" },
// { label: "Ink Mark", value: "Ink Mark" },
// { label: "vern_lang_hin", value: "vern_lang_hin" },
// { label: "vern_lang_mar", value: "vern_lang_mar" },
// { label: "vern_lang_tam", value: "vern_lang_tam" },
// { label: "vern_lang_ben", value: "vern_lang_ben" },
// { label: "vern_lang_kan", value: "vern_lang_kan" },
// { label: "vern_lang_ori", value: "vern_lang_ori" },
// { label: "vern_lang_tel", value: "vern_lang_tel" },
// { label: "vern_lang_guj", value: "vern_lang_guj" },
// { label: "vern_lang_mal", value: "vern_lang_mal" },
// { label: "vern_lang_pan", value: "vern_lang_pan" },
// { label: "vern_lang_asm", value: "vern_lang_asm" },
// ];

// const DefectedResults = ({
//   data = [],
//   setSelectedDefectsPage,
//   handleRemoveClick,
//   selectedDefectsPage,
//   handleSelectDefectChange,
//   handleRemarksChange,
//   handleSubmitMetaField,
//   handleMetaFieldInputChange,
//   updateData,
// }) => {
//   // Filter out Page 1 and Page 2 from being displayed
//   const dataExcludingPages1And2 = data.filter(
//     (item) => item.defectPage !== 1 && item.defectPage !== 2
//   );

//   const filteredData = data?.find(
//     (item) => item.defectPage === selectedDefectsPage
//   );
//   const defectFounds = filteredData?.defectFounds || [];
//   const filteredDefectOptions = OPTIONS.filter(
//     (item) => !defectFounds?.some((defect) => defect === item.label)
//   );

//   const showToast = () => {
//     setSelectedDefectsPage(null);
//     toast.info("To Save it permanently please Click on Update Defects", {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: 2000,
//     });
//   };

//   return (
//     <div className="w-full">
//       <div>
//         <MetaDataField
//           handleSubmitMetaField={handleSubmitMetaField}
//           handleMetaFieldInputChange={handleMetaFieldInputChange}
//           updateData={updateData}
//         />
//       </div>
//       <DoclabelDefect updateData={updateData} />
//       {/* <div className="bg-white shadow-md p-2 rounded-md mb-2">
//         <div className="font-bold text-base">Document Level Defects</div>
//         <div className="flex justify-between">
//           <div className="bg-[#4D64DC] p-1 rounded mt-1 text-white">Prayer</div>
//           <div className="bg-red-500 p-1 rounded mt-1 text-white">Not Present</div>
//         </div>
//       </div> */}
//       <ToastContainer />
//       {dataExcludingPages1And2.map((item) => {
//         return (
//           <div
//             key={item?.defectPage}
//             className="bg-white shadow-md p-2 rounded-md mb-2"
//           >
//             <div className="flex items-center justify-between">
//               <h1 className="text-gray-600 ">Page No : {item.defectPage}</h1>
//               <button
//                 onClick={() => setSelectedDefectsPage(item.defectPage)}
//                 className="border bg-indigo-900 text-white rounded px-1 shadow-md"
//               >
//                 Add Defect
//               </button>
//             </div>
//             <hr className="mt-2" />
//             <div className="mt-1 flex flex-wrap">
//               {item.defectFounds.map((defect) => (
//                 <div
//                   key={defect.id}
//                   className="bg-red-500 p-1 rounded m-1 text-white"
//                 >
//                   {defect}
//                   <button
//                     className="ml-2"
//                     onClick={() => handleRemoveClick(defect, item.defectPage)}
//                   >
//                     <FontAwesomeIcon icon={faXmark} color="indigo" />
//                   </button>
//                 </div>
//               ))}
//             </div>
//             {selectedDefectsPage === item.defectPage && (
//               <div>
//                 <hr className="my-2" />
//                 <div>
//                   <label className="text-gray-600">Select Defect: </label>
//                   <select
//                     onChange={handleSelectDefectChange}
//                     value=""
//                     className="border rounded focus:outline-none"
//                   >
//                     <option value="" disabled>
//                       Select an option
//                     </option>
//                     {filteredDefectOptions?.map((item, index) => (
//                       <option key={index} value={item.value}>
//                         {item.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//                 <div>
//                   <label className="text-gray-600">Remarks:</label>
//                   <textarea
//                     name="remarks"
//                     value={item?.remarks || ""}
//                     onChange={(e) => handleRemarksChange(e)}
//                     className="border rounded p-2 focus:outline-none w-full"
//                     rows="2"
//                   />
//                 </div>
//                 <div className="flex justify-end">
//                   <button
//                     className="border px-2 shadow-md rounded  bg-indigo-900 text-white"
//                     onClick={showToast}
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default DefectedResults;

// DefectedResults.js
import React from "react";
import MetaDataField from "./MetaDataField";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoclabelDefect from "./DoclabelDefect";
import PageLevelDefects from "./PageLevelDefect"; // Import the new component

const DefectedResults = ({
  data = [],
  setSelectedDefectsPage,
  handleRemoveClick,
  selectedDefectsPage,
  handleSelectDefectChange,
  handleRemarksChange,
  handleSubmitMetaField,
  handleMetaFieldInputChange,
  updateData,
  setUpdateData,
}) => {
  return (
    <div className="w-full">
      <MetaDataField
        handleSubmitMetaField={handleSubmitMetaField}
        handleMetaFieldInputChange={handleMetaFieldInputChange}
        updateData={updateData}
      />
      <DoclabelDefect updateData={updateData} setUpdateData={setUpdateData} />
      <PageLevelDefects
        data={data}
        setSelectedDefectsPage={setSelectedDefectsPage}
        handleRemoveClick={handleRemoveClick}
        selectedDefectsPage={selectedDefectsPage}
        handleSelectDefectChange={handleSelectDefectChange}
        handleRemarksChange={handleRemarksChange}
        updateData={updateData}
      />
      <ToastContainer />
    </div>
  );
};

export default DefectedResults;
