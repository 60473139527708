import InputLayout from "./InputLayout";

function Respondent({
  isEditable,
  respondentDetails = {},
  setRespondentDetails = () => {},
}) {
  // const [petitionerData, setPetitionerData] = useState({
  //   MainRespondentName: data?.MainRespondentName,
  //   RespondentType: data?.RespondentType,
  //   RespondentAge: data?.RespondentAge,
  //   RespondentParent: data?.RespondentParent,
  //   RespondentSpouse: data?.RespondentSpouse,
  //   RespondentMobile: data?.RespondentMobile,
  //   RespondentEMail: data?.RespondentEMail,
  //   RespondentCity: data?.RespondentCity,
  //   RespondentDistrict: data?.RespondentDistrict,
  //   RespondentState: data?.RespondentState,
  //   RespondentPincode: data?.RespondentPincode,
  //   RespondentAddress: data?.RespondentAddress,
  // });
  // useEffect(() => {
  //   setPetitionerData({
  //     MainRespondentName: data?.MainRespondentName,
  //     RespondentType: data?.RespondentType,
  //     RespondentAge: data?.RespondentAge,
  //     RespondentParent: data?.RespondentParent,
  //     RespondentSpouse: data?.RespondentSpouse,
  //     RespondentMobile: data?.RespondentMobile,
  //     RespondentEMail: data?.RespondentEMail,
  //     RespondentCity: data?.RespondentCity,
  //     RespondentDistrict: data?.RespondentDistrict,
  //     RespondentState: data?.RespondentState,
  //     RespondentPincode: data?.RespondentPincode,
  //     RespondentAddress: data?.RespondentAddress,
  //   });
  // }, [data]);

  // const handleSaveDraft = (e) => {
  //   e.preventDefault();
  //   setUpdatedData((prev) => ({
  //     ...prev,
  //     MainRespondentName: petitionerData?.MainRespondentName,
  //     RespondentType: petitionerData?.RespondentType,
  //     RespondentAge: petitionerData?.RespondentAge,
  //     RespondentParent: petitionerData?.RespondentParent,
  //     RespondentSpouse: petitionerData?.RespondentSpouse,
  //     RespondentMobile: petitionerData?.RespondentMobile,
  //     RespondentEMail: petitionerData?.RespondentEMail,
  //     RespondentCity: petitionerData?.RespondentCity,
  //     RespondentDistrict: petitionerData?.RespondentDistrict,
  //     RespondentState: petitionerData?.RespondentState,
  //     RespondentPincode: petitionerData?.RespondentPincode,
  //     RespondentAddress: petitionerData?.RespondentAddress,
  //   }));
  //   setIsEditable(false);
  // };

  return (
    <div className="flex justify-center pb-4">
      <form
        className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
        // onSubmit={handleSaveDraft}
      >
        <div className="flex justify-between items-center">
          <div className="text-[#192839]  text-base font-semibold">
            Respondent Information
          </div>
        </div>
        <hr className="my-4"></hr>

        <InputLayout
          isEditable={isEditable}
          petitionerData={respondentDetails}
          setPetitionerData={setRespondentDetails}
        />

        {/* <div className="flex justify-center gap-4">
          <button
            className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              setActiveTab("Petitioner");
            }}
          >
            Previous
          </button>
          {authRole !== "User" ? (
            <button
              className="bg-green-700 hover:bg-green-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save Draft
            </button>
          ) : null}
          <button
            className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              setActiveTab("Earlier_Courts");
            }}
          >
            Next
          </button>
        </div> */}
      </form>
    </div>
  );
}

export default Respondent;
