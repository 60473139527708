import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OPTIONS = [
  { label: "Signature Absent", value: "Signature Absent" },
  { label: "Blurred Page", value: "Blurred Page" },
  { label: "Missing Page Number", value: "Missing Page Number" },
  { label: "Ink Mark", value: "Ink Mark" },
  { label: "vern_lang_hin", value: "vern_lang_hin" },
  { label: "vern_lang_mar", value: "vern_lang_mar" },
  { label: "vern_lang_tam", value: "vern_lang_tam" },
  { label: "vern_lang_ben", value: "vern_lang_ben" },
  { label: "vern_lang_kan", value: "vern_lang_kan" },
  { label: "vern_lang_ori", value: "vern_lang_ori" },
  { label: "vern_lang_tel", value: "vern_lang_tel" },
  { label: "vern_lang_guj", value: "vern_lang_guj" },
  { label: "vern_lang_mal", value: "vern_lang_mal" },
  { label: "vern_lang_pan", value: "vern_lang_pan" },
  { label: "vern_lang_asm", value: "vern_lang_asm" },
];

const PageLevelDefects = ({
  data = [],
  setSelectedDefectsPage,
  handleRemoveClick,
  selectedDefectsPage,
  handleSelectDefectChange,
  handleRemarksChange,
}) => {
  // const dataExcludingPages1And2 = data.filter(
  //   (item) => item.defectPage !== 1 && item.defectPage !== 2
  // );

  const filteredData = data?.find(
    (item) => item.defectPage === selectedDefectsPage
  );
  const defectFounds = filteredData?.defectFounds || [];
  const filteredDefectOptions = OPTIONS.filter(
    (item) => !defectFounds?.some((defect) => defect === item.label)
  );

  const showToast = () => {
    setSelectedDefectsPage(null);
    toast.info("To Save it permanently please Click on Update", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  const [showPageLevelDefect, setShowPageLevelDefect] = useState(false);
  return (
    <div
      className="bg-white shadow my-4 mx-1 rounded p-2"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-[#40566D] font-semibold">Page level Defects</h1>
        <button
          className="border border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center px-1 shadow-md"
          onClick={() => setShowPageLevelDefect((prev) => !prev)}
        >
          {showPageLevelDefect ? "Collapse All" : "View All"}
          <div className="w-5">
            {showPageLevelDefect ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </div>
        </button>
      </div>
      <ToastContainer />
      {showPageLevelDefect &&
        //dataExcludingPages1And2.map((item) => {
        data.map((item) => {
          return (
            <div
              key={item?.defectPage}
              style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
              className="p-2 rounded-md my-2"
            >
              <div className="flex items-center justify-between">
                <h1 className="text-[#40566D] text-sm font-semibold">
                  Page No : {item.defectPage}
                </h1>

                <button
                  onClick={() =>
                    setSelectedDefectsPage(
                      selectedDefectsPage === item.defectPage
                        ? null
                        : item.defectPage
                    )
                  }
                  className="border flex items-center border-[#5E48FC] text-[#5E48FC] text-sm rounded-lg px-2 py-1"
                >
                  {selectedDefectsPage === item.defectPage
                    ? "Cancel"
                    : "Add Defects"}
                </button>
              </div>

              <div className="mt-1 flex flex-wrap">
                {item.defectFounds.map((defect) => {
                  const isCritical = [
                    "Blurred Page",
                    "Missing Page Number",
                    "Signature Absent",
                    "NOT in OCR format",
                  ].includes(defect);
                  const bgColor = isCritical ? "bg-red-500" : "bg-blue-500";

                  return (
                    <div
                      key={defect.id}
                      className={`${bgColor} font-normal text-sm px-3 py-1 rounded-3xl m-1 text-white`}
                    >
                      {defect}
                      <button
                        className="ml-2"
                        onClick={() =>
                          handleRemoveClick(defect, item.defectPage)
                        }
                      >
                        <FontAwesomeIcon icon={faXmark} color="white" />
                      </button>
                    </div>
                  );
                })}
              </div>
              {selectedDefectsPage === item.defectPage && (
                <div className="py-2">
                  <div className="flex justify-end">
                    <select
                      onChange={handleSelectDefectChange}
                      value=""
                      className="border text-[#40566D] text-sm py-1 rounded focus:outline-none"
                    >
                      <option value="" disabled>
                        Select a defect to add
                      </option>
                      {filteredDefectOptions?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="text-[#40566D] text-sm font-semibold">
                      Remarks:
                    </label>
                    <textarea
                      name="remarks"
                      value={item?.remarks || ""}
                      onChange={handleRemarksChange}
                      className="border font-normal text-[#40566D] text-sm rounded p-2 focus:outline-none w-full"
                      rows="2"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="border px-3 py-1 rounded-lg shadow-md text-sm bg-[#5E48FC] text-white"
                      onClick={showToast}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default PageLevelDefects;

// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faAngleDown,
//   faAngleUp,
//   faXmark,
// } from "@fortawesome/free-solid-svg-icons";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const OPTIONS = [
//   { label: "Signature Absent", value: "Signature Absent" },
//   { label: "Blurred Page", value: "Blurred Page" },
//   { label: "Missing Page Number", value: "Missing Page Number" },
//   { label: "Ink Mark", value: "Ink Mark" },
//   { label: "vern_lang_hin", value: "vern_lang_hin" },
//   { label: "vern_lang_mar", value: "vern_lang_mar" },
//   { label: "vern_lang_tam", value: "vern_lang_tam" },
//   { label: "vern_lang_ben", value: "vern_lang_ben" },
//   { label: "vern_lang_kan", value: "vern_lang_kan" },
//   { label: "vern_lang_ori", value: "vern_lang_ori" },
//   { label: "vern_lang_tel", value: "vern_lang_tel" },
//   { label: "vern_lang_guj", value: "vern_lang_guj" },
//   { label: "vern_lang_mal", value: "vern_lang_mal" },
//   { label: "vern_lang_pan", value: "vern_lang_pan" },
//   { label: "vern_lang_asm", value: "vern_lang_asm" },
// ];

// const PageLevelDefects = ({
//   data = [],
//   setSelectedDefectsPage,
//   handleRemoveClick,
//   selectedDefectsPage,
//   handleSelectDefectChange,
//   handleRemarksChange,
// }) => {
//   const dataExcludingPages1And2 = data.filter(
//     (item) => item.defectPage !== 1 && item.defectPage !== 2
//   );

//   const filteredData = data?.find(
//     (item) => item.defectPage === selectedDefectsPage
//   );
//   const defectFounds = filteredData?.defectFounds || [];
//   const filteredDefectOptions = OPTIONS.filter(
//     (item) => !defectFounds?.some((defect) => defect === item.label)
//   );

//   const showToast = () => {
//     setSelectedDefectsPage(null);
//     toast.info("To Save it permanently please Click on Update", {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: 2000,
//     });
//   };

//   const [showPageLevelDefect, setShowPageLevelDefect] = useState(false);
//   return (
//     <div
//       className="bg-white shadow my-4 mx-1 rounded p-2"
//       style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
//     >
//       <div className="flex justify-between items-center">
//         <h1 className="text-[#40566D] font-semibold">Page level Defects</h1>
//         <button
//           className="border border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center px-1 shadow-md"
//           onClick={() => setShowPageLevelDefect((prev) => !prev)}
//         >
//           {showPageLevelDefect ? "Collapse All" : "View All"}
//           <div className="w-5">
//             {showPageLevelDefect ? (
//               <FontAwesomeIcon icon={faAngleUp} />
//             ) : (
//               <FontAwesomeIcon icon={faAngleDown} />
//             )}
//           </div>
//         </button>
//       </div>
//       <ToastContainer />
//       {showPageLevelDefect &&
//         dataExcludingPages1And2.map((item) => {
//           return (
//             <div
//               key={item?.defectPage}
//               style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
//               className="p-2 rounded-md my-2"
//             >
//               <div className="flex items-center justify-between">
//                 <h1 className="text-[#40566D] text-sm font-semibold">
//                   Page No : {item.defectPage}
//                 </h1>

//                 <button
//                   onClick={() =>
//                     setSelectedDefectsPage(
//                       selectedDefectsPage === item.defectPage
//                         ? null
//                         : item.defectPage
//                     )
//                   }
//                   className="border flex items-center border-[#5E48FC] text-[#5E48FC] text-sm rounded-lg px-2 py-1"
//                 >
//                   {selectedDefectsPage === item.defectPage
//                     ? "Cancel"
//                     : "Add Defects"}
//                 </button>
//               </div>

//               <div className="mt-1 flex flex-wrap">
//                 {item.defectFounds.map((defect) => (
//                   <div
//                     key={defect.id}
//                     className="bg-[#D92D20] font-normal text-sm px-3 py-1 rounded-3xl m-1 text-white"
//                   >
//                     {defect}
//                     <button
//                       className="ml-2"
//                       onClick={() => handleRemoveClick(defect, item.defectPage)}
//                     >
//                       <FontAwesomeIcon icon={faXmark} color="white" />
//                     </button>
//                   </div>
//                 ))}
//               </div>
//               {selectedDefectsPage === item.defectPage && (
//                 <div className="py-2">
//                   {/* <hr className="my-2" /> */}
//                   <div className="flex justify-end">
//                     {/* <label className="text-[#40566D] text-sm font-semibold me-2">
//                       Select Defect :
//                     </label> */}
//                     <select
//                       onChange={handleSelectDefectChange}
//                       value=""
//                       className="border text-[#40566D] text-sm py-1  rounded focus:outline-none"
//                     >
//                       <option value="" disabled>
//                         Select a defect to add
//                       </option>
//                       {filteredDefectOptions?.map((option, index) => (
//                         <option key={index} value={option.value}>
//                           {option.label}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                   <div>
//                     <label className="text-[#40566D] text-sm font-semibold">
//                       Remarks:
//                     </label>
//                     <textarea
//                       name="remarks"
//                       value={item?.remarks || ""}
//                       onChange={handleRemarksChange}
//                       className="border font-normal text-[#40566D] text-sm rounded p-2 focus:outline-none w-full"
//                       rows="2"
//                     />
//                   </div>
//                   <div className="flex justify-end">
//                     <button
//                       className="border px-3 py-1 rounded-lg shadow-md text-sm bg-[#5E48FC] text-white"
//                       onClick={showToast}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//     </div>
//   );
// };

// export default PageLevelDefects;
