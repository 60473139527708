// import React, { useEffect, useState } from "react";
import InputLayout from "./InputLayout";

function Petitioner({
  petitionerDetails = {},
  setPetitionerDetails = () => {},
  isEditable,
  // setIsEditable,
  // setUpdatedData = () => {},
}) {
  // const [petitionerData, setPetitionerData] = useState({
  //   MainPetitionerName: data?.MainPetitionerName,
  //   PetitionerType: data?.PetitionerType,
  //   PetitionerAge: data?.PetitionerAge,
  //   PetitionerParent: data?.PetitionerParent,
  //   PetitionerSpouse: data?.PetitionerSpouse,
  //   PetitionerMobile: data?.PetitionerMobile,
  //   PetitionerEMail: data?.PetitionerEMail,
  //   PetitionerCity: data?.PetitionerCity,
  //   PetitionerDistrict: data?.PetitionerDistrict,
  //   PetitionerState: data?.PetitionerState,
  //   PetitionerPincode: data?.PetitionerPincode,
  //   PetitionerAddress: data?.PetitionerAddress,
  // });

  // useEffect(() => {
  //   setPetitionerData({
  //     MainPetitionerName: data?.MainPetitionerName,
  //     PetitionerType: data?.PetitionerType,
  //     PetitionerAge: data?.PetitionerAge,
  //     PetitionerParent: data?.PetitionerParent,
  //     PetitionerSpouse: data?.PetitionerSpouse,
  //     PetitionerMobile: data?.PetitionerMobile,
  //     PetitionerEMail: data?.PetitionerEMail,
  //     PetitionerCity: data?.PetitionerCity,
  //     PetitionerDistrict: data?.PetitionerDistrict,
  //     PetitionerState: data?.PetitionerState,
  //     PetitionerPincode: data?.PetitionerPincode,
  //     PetitionerAddress: data?.PetitionerAddress,
  //   });
  // }, [data]);

  // const handleSaveDraft = (e) => {
  //   e.preventDefault();
  //   setUpdatedData((prev) => ({
  //     ...prev,
  //     MainPetitionerName: petitionerData?.MainPetitionerName,
  //     PetitionerType: petitionerData?.PetitionerType,
  //     PetitionerAge: petitionerData?.PetitionerAge,
  //     PetitionerParent: petitionerData?.PetitionerParent,
  //     PetitionerSpouse: petitionerData?.PetitionerSpouse,
  //     PetitionerMobile: petitionerData?.PetitionerMobile,
  //     PetitionerEMail: petitionerData?.PetitionerEMail,
  //     PetitionerCity: petitionerData?.PetitionerCity,
  //     PetitionerDistrict: petitionerData?.PetitionerDistrict,
  //     PetitionerState: petitionerData?.PetitionerState,
  //     PetitionerPincode: petitionerData?.PetitionerPincode,
  //     PetitionerAddress: petitionerData?.PetitionerAddress,
  //   }));
  //   setIsEditable(false);
  // };

  return (
    <div>
      <div className="flex justify-center pb-4">
        <form
          className="border px-12  py-4 rounded-lg shadow-lg max-w-1/2 w-4/5"
          // onSubmit={handleSaveDraft}
        >
          <div className="flex justify-between items-center">
            <div className=" text-[#192839]  text-base font-semibold">
              Petitioner Information
            </div>
            {/* {authRole !== "User" ? (
              <div>
                {isEditable ? (
                  <button
                    onClick={handleCancel}
                    className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    onClick={handleEdit}
                    className="bg-indigo-600 px-2.5 py-0.5 rounded shadow-md hover:bg-indigo-800 active:bg-red-600 text-white"
                  >
                    Edit
                  </button>
                )}
              </div>
            ) : null} */}
          </div>
          <hr className="my-4"></hr>

          <InputLayout
            isEditable={isEditable}
            petitionerData={petitionerDetails}
            setPetitionerData={setPetitionerDetails}
          />

          {/* <div className="flex justify-center gap-4">
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                setActiveTab("Case_Detail");
              }}
            >
              Previous
            </button>
            {authRole !== "User" ? (
              <button
                className="bg-green-700 hover:bg-green-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Save Draft
              </button>
            ) : null}
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white py-1 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                setActiveTab("Respondent");
              }}
            >
              Next
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default Petitioner;
