import axios from "axios";
import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Routing";
// import { toast } from "react-toastify";
const { REACT_APP_API_KEY } = process.env;

const useEfileHistory = ({ id }) => {
  const userState = useContext(UserContext);
  const {
    setShowNavigation = () => { },
    setIsLoggedIn = () => { },
  } = userState || {};
  const navigate = useNavigate();

  const [updatedData, setUpdatedData] = useState({});

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  const getEfilingData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_KEY}/getEfilingDataById`,
        {
          params: {
            id: id,
          },
          // withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setUpdatedData(response.data._source);
    } catch (err) {
      console.error(err || "Oops somthing went wrong");
      if (err.response.status === 401) {
        // toast.error("Invalid Token. Please Login!", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 2000,
        // });
        console.log("Invalid Token! Please login again");
        setShowNavigation(false);
        navigate("/");
        setIsLoggedIn(false);
      }
    }
  }, [id, token, setShowNavigation, navigate, setIsLoggedIn]);

  useEffect(() => {
    getEfilingData();
  }, [getEfilingData]);

  return {
    updatedData,
    setUpdatedData,
  };
};

export default useEfileHistory;
