import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DefectedResults from "./DefectedResults";
import useGetSelectedData from "./useGetSelectedData";
import DefectedResultsLoader from "./DefectedResultsLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewDefectUploadedDocument from "./ViewDefectUploadedDocument";
import UpdateModal from "./UpdateModal";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faDownload } from "@fortawesome/free-solid-svg-icons";
const { REACT_APP_API_KEY } = process.env;

export const formatDateTime = (timestamp) => {
  return moment(timestamp).format("DD MMM YYYY");
};
const VERNACULAR_LANGUAGE = [
  "Hindi",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];

const defectStrings = [
  "SLP format is incorrect",
  "Findings of high court NOT mentioned in SLP",
  "Drawn by NOT mentioned",
  "Certificate NOT filed in support of SLP by AoR",
  "Date of filing NOT mentioned",
  "List of Dates Absent",
  "Office report on Limitation Absent",
  "Page number of annexures NOT mentioned in the list of dates",
  "Description of annexures NOT mentioned in the list of dates",
  "Description of annexures NOT mentioned in the index",
  "Affidavit NOT filed",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent",
  "Deponent capacity Not Appicable",
  "Blanks NOT filed in affidavit",
  "Memo of parties NOT filed",
  "Listing Proforma NOT filed properly",
  "Vakalatnama NOT filed properly",
  "Memo of Appearance NOT filed properly",
  "Defect in Non-mentioning of days of delay",
  "All order dates NOT present in list of dates and events",
  "All Case numbers NOT matched between annexures and index",
  "All annexure dates NOT matched index dates",
  "All annexure descriptions NOT matched index descriptions",
  "Application for exemption from filing an official translation mentioned but NOT present",
];

const DEFAULT_DOC_LEVEL = {
  "SLP format is incorrect":
    "Non-filing of SLP(Civil) in Form No.28 with certificate as per Notification dated 17.6.1997",
  "Findings of high court NOT mentioned in SLP":
    "Non-mentioning of the findings of the High Court/ Courts below in the list of dates and para 1 of SLP.",
  "Drawn by NOT mentioned": "Non-mentioning of date of filing/ drawn by.",
  "Certificate NOT filed in support of SLP by AoR":
    "Non-filing of certificate by AoR in support of SLP.",
  "Date of filing NOT mentioned": "Non-mentioning of date of filing/ drawn by.",
  "List of Dates Absent": "Non-filing of brief list of dates/ events.",
  "Office report on Limitation Absent":
    "Non-filing of office report on limitation with cause title on Green/ White sheet.",
  "Page number of annexures NOT mentioned in the list of dates":
    "Non-mentioning of page numbers of annexures in the list of dates/ petition.",
  "Description of annexures NOT mentioned in the list of dates":
    "Incorrect mentioning of description of annexures in list of dates/ petition.",
  "Description of annexures NOT mentioned in the index":
    "Incorrect mentioning of description of annexures in Index.",
  "Affidavit NOT filed": "Non-filing of affidavit properly executed.",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent":
    "Non-filing of affidavit containing the statement that the facts stated in the petition are true to the knowledge, information and belief of the deponent.",
  "Deponent capacity Not Appicable":
    "Non-disclosure of deponent’s capacity [in case matter is filed on behalf of or by organisation/ company/ pairokar].",
  "Blanks NOT filed in affidavit": "Blanks in the affidavit.",
  "Memo of parties NOT filed":
    "Non-filing of Memo of Parties, as detailed cause title not given in the impugned judgment.",
  "Listing Proforma NOT filed properly":
    "Non-inclusion of complete listing proforma filled in, signed in the paper-books.",
  "Vakalatnama NOT filed properly":
    "Improper execution of Vakalatnama/Memo of Appearance.",
  "Memo of Appearance NOT filed properly":
    "Improper execution of Vakalatnama/Memo of Appearance.",
  "Defect in Non-mentioning of days of delay":
    "Non-mentioning of the number of days of delay .",
  "All order dates NOT present in list of dates and events":
    "All order dates NOT present in list of dates and events",
  "All Case numbers NOT matched between annexures and index":
    "Case No.(s) of annexures —–not given.",
  "All annexure dates NOT matched index dates":
    "Date(s) of annexures….. do not tally.",
  "All annexure descriptions NOT matched index descriptions":
    "Incorrect mentioning of description of annexures in Index.",
  "Application for exemption from filing an official translation mentioned but NOT present":
    "Non-filing of translation of vernacular document(s)",
};

const DEFAULT_PAGE_LEVEL = {
  "Blurred Page":
    "PAGE NO.............NOTCLEAR/ LEGIBLE/ SMALLFONT/ DIM/ MISSING.",
  "Missing Page Number":
    "Page Nos. not indicated on the right side of top of pages",
  "Signature Absent":
    "Non-bearing of signature of the counsel/ party-in-person in petition/ application/ certificate",
  Hindi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Assamese: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Bengali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Bodo: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Dogri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Gujarati: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Kannada: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Kashmiri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Konkani: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Maithili: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Malayalam: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Manipuri: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Marathi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Nepali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Odia: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Punjabi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Sanskrit: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Santali: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Sindhi: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Tamil: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Telugu: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
  Urdu: "NON-FILING OF TRANSLATION OF VERNACULAR DOCUMENT(S).",
};
const defectCodes = {
  "Listing Proforma NOT filed properly": "24.2",
  "Vakalatnama NOT filed properly": "8.1",
  "Defect in Non-mentioning of days of delay": "17.1",
  "Blanks NOT filed in affidavit": "5.5",
  "Memo of parties NOT filed": "15.1",
  "All annexure dates NOT matched index dates": "18.3",
  "All Case numbers NOT matched between annexures and index": "18.5",
  "Certificate NOT filed in support of SLP by AoR": "1.3",
  "Page number of annexures NOT mentioned in the list of dates": "2.6",
  "Memo of Appearance NOT filed properly": "8.1",
  "Application for exemption from filing an official translation mentioned but NOT present":
    "6.1",

  "SLP format is incorrect": "1.1",
  "Findings of high court NOT mentioned in SLP": "1.5",
  "Drawn by NOT mentioned": "1.7",
  "Date of filing NOT mentioned": "1.7",
  "List of Dates Absent": "2.1",
  "Office report on Limitation Absent": "2.5",
  "Description of annexures NOT mentioned in the list of dates": "2.7",
  "Description of annexures NOT mentioned in the index": "2.8",
  "Affidavit NOT filed": "5.3",
  "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent":
    "5.2",
  "Deponent capacity Not Appicable": "5.4",
  "All order dates NOT present in list of dates and events": "N/A",
  "All annexure descriptions NOT matched index descriptions": "2.8",
};
const pageLevelDefects = [
  "Blurred Page",
  "Missing Page Number",
  "Signature Absent",
  "Hindi",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];

const pageLevelDefectsCode = {
  "Blurred Page": "3.2",
  "Missing Page Number": "3.5",
  "Signature Absent": "4.1",
  "NOT in OCR format": "N/A",
  Hindi: "6.1",
  Assamese: "6.1",
  Bengali: "6.1",
  Bodo: "6.1",
  Dogri: "6.1",
  Gujarati: "6.1",
  Kannada: "6.1",
  Kashmiri: "6.1",
  Konkani: "6.1",
  Maithili: "6.1",
  Malayalam: "6.1",
  Manipuri: "6.1",
  Marathi: "6.1",
  Nepali: "6.1",
  Odia: "6.1",
  Punjabi: "6.1",
  Sanskrit: "6.1",
  Santali: "6.1",
  Sindhi: "6.1",
  Tamil: "6.1",
  Telugu: "6.1",
  Urdu: "6.1",
};

const DefectTab = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const encodedData = urlParams.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  const { id } = decodedData || {};
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const navigate = useNavigate();
  const contentRef = useRef(null);
  let serialNumber = 1;

  // ProgressBar States
  const [progress, setProgress] = useState(15);

  const {
    setSelectedDefectsPage,
    handleMetaFieldInputChange,
    handleRemoveClick,
    handleUpdate,
    handleRemarksChange,
    handleSelectDefectChange,
    handleSubmitMetaField,
    selectedDefectsPage,
    updateData,
    selectedData,
    setUpdateData,
  } = useGetSelectedData({ id, setShowUpdateModal });

  const defectsCategory = updateData?.defectsCategory;

  const { filename } = updateData || {};

  //Messages For Loader
  const messages = [
    "Validating document",
    "Converting the pages to images",
    "Extracting text from the pages",
    "Checking for defects",
    "Defect detection(Dim Page, Missing Page no., etc.)",
    "Finalizing results",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (selectedData.length === 0) {
      let intervalId;
      if (currentMessageIndex < 5) {
        intervalId = setInterval(() => {
          setCurrentMessageIndex((prevIndex) => prevIndex + 1);
          setProgress(progress + 15);
        }, 10000); // 10 seconds
      }

      return () => clearInterval(intervalId);
    }
  }, [currentMessageIndex, progress, selectedData]);

  const handleAuditHistory = () => {
    navigate("/SelectedFileAnalysis/AuditHistory", { state: id });
  };

  const token = JSON.parse(localStorage.getItem("userDetails")).token;

  useEffect(() => {
    fetch(`${REACT_APP_API_KEY}/getDefectPdf?filename=${id}`, {
      // credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setIsPdfLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the PDF:", error);
      });
  }, [id, token]);

  const handleDownload = () => {
    const element = contentRef.current;
    const opt = {
      margin: [16, 6, 16, 6],
      filename: `NyaayAI_${filename}`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: 16, // or "smart", default is 16
      },
    };
    html2pdf().set(opt).from(element).save();
  };
  const { doc_level_defects = [], defects = [], uploadedAt } = updateData;
  return (
    // <div className="w-full h-full bg-white">
    //     <div className="w-full">
    //         <div className="bg-[#E7EAF7] shadow-md p-3 my-3.5 mx-2 rounded flex flex-wrap items-center sm:justify-between justify-center">
    //             <div className="flex flex-col">
    //                 <div className="mx-auto font-bold">Legal Document Digitization</div>

    //                 <div className="mx-auto text-[#4D64DC]">
    //                     {!isPdfLoading ? filename : "---"}
    //                 </div>
    //             </div>
    //             <div className="mt-1 sm:mt-0 sm:flex flex flex-col sm:flex-row justify-center items-center">
    //                 <ToastContainer />
    //                 <button
    //                     onClick={() => setShowUpdateModal(true)}
    //                     className="bg-[#4D64DC] text-white px-2.5 py-1.5 cursor-pointer rounded-md mr-2"
    //                 >
    //                     Update Defects
    //                 </button>
    //             </div>
    //         </div>
    //     </div>

    <div className="py-2 mx-6">
      <ToastContainer />
      <div className="flex justify-between">
        <button onClick={() => navigate("/DefectAnalysis")}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h1 className="text-[#40566D] text-lg font-semibold">
          Defect Detection
        </h1>
        <h1 className="text-[#40566D] text-lg font-medium">{filename}</h1>
        <button className=" active:animate-bounce" onClick={handleDownload}>
          <FontAwesomeIcon icon={faDownload} color="#5E48FC" />
        </button>
      </div>
      <hr className="sm:-mx-6 mt-2"></hr>

      <div className="sm:flex justify-between w-full my-4">
        <div className="sm:w-1/2 h-full">
          {isPdfLoading ? (
            <div className="mt-12 flex justify-center items-center">
              <Oval
                height={120}
                width={120}
                color="Indigo"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="Indigo"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : selectedData.length > 0 ? (
            <ViewDefectUploadedDocument pdfUrl={pdfUrl} />
          ) : (
            <div className="mt-12 flex justify-center items-center text-[#40566D] text-sm font-medium">
              Document in process. Hold on for sometime.
            </div>
          )}
        </div>

        <div className="overflow-y-auto max-h-screen sm:w-1/2 ps-2 sm:border-l sm:ml-2 sm:-mt-4">
          <div className="flex justify-between py-2 items-center">
            <div className="font-semibold text-[#40566D]">
              Digitization Result
            </div>

            <div className="sm:mt-0 sm:flex flex flex-col sm:flex-row">
              {selectedData.length > 0 ? (
                <button
                  className="bg-[#6C849D1F] text-[#192839] px-1.5 py-1 cursor-pointer rounded-lg"
                  onClick={() => handleAuditHistory()}
                >
                  Audit History
                </button>
              ) : null}
              <button
                onClick={() => setShowUpdateModal(true)}
                className="bg-[#5E48FC] ms-4 text-white px-6 py-1 cursor-pointer rounded-lg"
              >
                Update
              </button>
            </div>
          </div>
          <hr></hr>
          <div className="py-4" style={{ display: "none" }}>
            <div ref={contentRef} className="text-[#1F2A37] text-sm">
              <div className="text-lg flex justify-between items-center mb-4">
                <span> Processed by Nyaay AI</span>
                <span>{formatDateTime(uploadedAt)}</span>
              </div>
              <div className="text-lg mb-4 flex justify-center">{filename}</div>
              <div className="text-lg mb-4">Document Level Defects</div>
              <div className="flex w-full uppercase bg-[#F9FAFB] pb-4 text-sm font-medium">
                <div className="w-[10%] px-1 flex justify-center items-center">
                  S.No.
                </div>
                <div className="w-[15%] px-1 flex justify-center items-center">
                  Default Code
                </div>
                <div className="w-[35%] px-1 flex justify-center items-center">
                  Default
                </div>
                <div className="w-[40%] px-1 flex justify-center items-center">
                  Default Simple (Nyaay AI)
                </div>
              </div>
              {doc_level_defects
                .filter((defects) =>
                  defectStrings.includes(Object.values(defects)[0][0])
                )
                .map((defects, index) => {
                  const defectText = Object.values(defects)[0][0];
                  const defectCode = defectCodes[defectText] || "N/A"; // Assuming "N/A" if code not found
                  return (
                    <div
                      className="text-sm flex w-full border border-b-0 last:border-b"
                      key={index}
                    >
                      <div className="w-[10%] px-2 pb-4 border-r justify-center items-center flex">
                        {index + 1}
                      </div>
                      <div className="w-[15%] px-2 pb-4 items-center justify-center border-r flex">
                        {defectCode}
                      </div>
                      <div
                        className="pb-4 px-2 w-[35%] items-center border-r flex"
                        // style={{
                        //   wordWrap: "break-word",
                        //   wordBreak: "break-all",
                        // }}
                      >
                        {DEFAULT_DOC_LEVEL[defectText]}
                      </div>
                      <div
                        // style={{
                        //   wordWrap: "break-word",
                        //   wordBreak: "break-all",
                        // }}
                        className="pb-4 px-2 w-[35%] items-center flex"
                      >
                        {defectText}
                      </div>
                    </div>
                  );
                })}
              <hr></hr>

              <div className="py-4">
                <div className="text-lg mb-4">Page Level Defects</div>
                <div className="flex w-full uppercase bg-[#F9FAFB] pb-4 justify-center items-center text-sm font-medium">
                  <div className="w-[10%] flex justify-center px-1">S.No.</div>
                  <div className="w-[10%] flex justify-center items-center px-1">
                    Default Code
                  </div>
                  <div className="w-[35%] flex justify-center items-center px-1">
                    Default
                  </div>
                  <div className="w-[35%] flex justify-center items-center px-1">
                    Default Simple (Nyaay AI)
                  </div>
                  <div className="w-[10%] flex justify-center items-center px-1">
                    Page No.
                  </div>
                </div>
                {defects.map((item, index) => {
                  return item?.defectFounds.map((defect, defectIndex) => {
                    const isDefect = pageLevelDefects.includes(defect);
                    if (!isDefect) {
                      return null;
                    }
                    const defectCode = pageLevelDefectsCode[defect] || "N/A"; // Assuming "N/A" if code not found
                    const currentSerialNumber = serialNumber++;
                    return (
                      <div
                        className="text-sm flex w-full border border-b-0 last:border-b"
                        key={`${index}-${defectIndex}`}
                      >
                        <div className="w-[10%] px-2 pb-4 border-r items-center flex justify-center">
                          {currentSerialNumber}
                        </div>
                        <div className="w-[10%] px-2 pb-4 border-r items-center flex justify-center">
                          {defectCode}
                        </div>
                        <div
                          className="pb-4 px-2 border-r w-[35%] items-center flex"
                          // style={{
                          //   wordWrap: "break-word",
                          //   wordBreak: "break-all",
                          // }}
                        >
                          {DEFAULT_PAGE_LEVEL[defect]}
                        </div>
                        <div className="pb-4 px-2 border-r w-[35%] items-center flex">
                          {VERNACULAR_LANGUAGE.includes(defect)
                            ? `Vernacular Language - ${defect}`
                            : defect}
                        </div>
                        <div className="pb-4  flex items-center justify-center w-[10%]">
                          {item.defectPage}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>
          {/* Choosen Defects Category */}
          {defectsCategory?.length > 0 ? (
            <div>
              <div className="flex justify-center items-center font-bold">
                Selected Defects Category
              </div>
              <div className="mt-1 flex flex-wrap justify-center items-center mb-2">
                {defectsCategory.length > 0
                  ? defectsCategory.map((defect) => (
                      <div
                        key={defect.id}
                        className="bg-red-500 p-1 rounded m-1 text-white"
                      >
                        {defect}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          ) : null}
          <div className="flex flex-wrap items-center justify-center">
            {selectedData?.length > 0 ? (
              <DefectedResults
                data={selectedData}
                setSelectedDefectsPage={setSelectedDefectsPage}
                handleRemoveClick={handleRemoveClick}
                selectedDefectsPage={selectedDefectsPage}
                handleSelectDefectChange={handleSelectDefectChange}
                handleRemarksChange={handleRemarksChange}
                handleSubmitMetaField={handleSubmitMetaField}
                handleMetaFieldInputChange={handleMetaFieldInputChange}
                updateData={updateData}
                setUpdateData={setUpdateData}
                id={id}
                filename={filename}
              />
            ) : (
              <DefectedResultsLoader
                messages={messages}
                currentMessageIndex={currentMessageIndex}
                progress={progress}
              />
            )}
          </div>
        </div>
      </div>
      {showUpdateModal ? (
        <UpdateModal
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          handleUpdate={handleUpdate}
          id={id}
        />
      ) : null}
    </div>
  );
};

export default DefectTab;
