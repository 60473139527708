import {
  faAngleDown,
  faAngleUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { toast } from "react-toastify";

const OPTIONS = [
  {
    defectCategory: "Bookmark_defect",
    value: [
      { label: "Bookmarks present", value: "Bookmarks present" },
      { label: "Bookmarks absent", value: "Bookmarks absent" },
    ],
  },
  {
    defectCategory: "Synopsis_defect",
    value: [
      {
        label: "Synopsis and list of dates has more than 5 pages",
        value: "Synopsis and list of dates has more than 5 pages",
      },
      { label: "No defect in synopsis", value: "No defect in synopsis" },
      { label: "Synopsis does not exist", value: "Synopsis does not exist" },
    ],
  },
  {
    defectCategory: "Vakalatnama_defect",
    value: [
      { label: "Vakalatnama Present", value: "Vakalatnama Present" },
      { label: "Vakalatnama Not Present", value: "Vakalatnama Not Present" },
    ],
  },
  {
    defectCategory: "Prayer_defect",
    value: [
      { label: "Prayer Present", value: "Prayer Present" },
      { label: "Prayer Absent", value: "Prayer Absent" },
    ],
  },
  {
    defectCategory: "Memo_of_parties_defect",
    value: [
      { label: "Memo of Parties Present", value: "Memo of Parties Present" },
      { label: "Memo of Parties Absent", value: "Memo of Parties Absent" },
    ],
  },
];

const DoclabelDefect = ({ updateData = {}, setUpdateData }) => {
  const { doc_level_defects = [] } = updateData;
  const [showDocLabelDefect, setShowDocLabelDefect] = useState(false);
  const [addDefects, setShowAddDefects] = useState(false);
  const [availabelOptions, setAvailabelOptions] = useState(
    OPTIONS.reduce((acc, option) => {
      const defectExists = doc_level_defects.some((defect) =>
        defect.hasOwnProperty(option.defectCategory)
      );
      if (!defectExists) {
        acc.push(...option.value.map((item) => item.label));
      }
      return acc;
    }, [])
  );

  const handleAddDefect = (defect) => {
    const defectCategory = OPTIONS.find((option) => {
      return option.value.some((item) => item.value === defect);
    }).defectCategory;

    setUpdateData((prev) => ({
      ...prev,
      doc_level_defects: [
        ...prev.doc_level_defects,
        { [defectCategory]: [defect] },
      ],
    }));

    const defectsToRemove = OPTIONS.filter(
      (option) => option.defectCategory === defectCategory
    )[0].value.map((item) => item.label);
    const newOptions = availabelOptions.filter(
      (item) => !defectsToRemove.includes(item)
    );

    setAvailabelOptions(newOptions);
  };

  const handleRemoveDefect = (defect) => {
    const updatedDefects = doc_level_defects.filter(
      (d) => Object.values(d)[0][0] !== defect
    );
    setUpdateData((prev) => ({ ...prev, doc_level_defects: updatedDefects }));

    const newOptions = OPTIONS.reduce((acc, option) => {
      const defectExists = updatedDefects.some((defect) =>
        defect.hasOwnProperty(option.defectCategory)
      );
      if (!defectExists) {
        acc.push(...option.value.map((item) => item.label));
      }
      return acc;
    }, []);

    setAvailabelOptions(newOptions);
  };

  const handleRemarksChange = (e) => {
    const selectedValue = e.target.value;
    setUpdateData((prev) => ({ ...prev, remarks: selectedValue }));
  };

  const showToast = () => {
    toast.info("To Save it permanently please Click on Update", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };

  const defectStrings = [
    "SLP format is incorrect",
    "Findings of high court NOT mentioned in SLP",
    "Drawn by NOT mentioned",
    "Certificate NOT filed in support of SLP by AoR",
    "Date of filing NOT mentioned",
    "List of Dates Absent",
    "Office report on Limitation Absent",
    "Page number of annexures NOT mentioned in the list of dates",
    "Description of annexures NOT mentioned in the list of dates",
    "Description of annexures NOT mentioned in the index",
    "Affidavit NOT filed",
    "NO statement acknowleding the facts stated in the petition are true to the knowledge, information and belief of the deponent",
    "Deponent capacity Not Appicable",
    "Blanks NOT filed in affidavit",
    "Memo of parties NOT filed",
    "Listing Proforma NOT filed properly",
    "Vakalatnama NOT filed properly",
    "Memo of Appearance NOT filed properly",
    "Defect in Non-mentioning of days of delay",
    "All order dates NOT present in list of dates and events",
    "All Case numbers NOT matched between annexures and index",
    "All annexure dates NOT matched index dates",
    "All annexure descriptions NOT matched index descriptions",
    "Application for exemption from filing an official translation mentioned but NOT present",
    "Vakalatnama NOT filed",
  ];

  return (
    <div
      className="bg-white shadow my-2 mx-1 rounded p-2"
      style={{ boxShadow: "0 0 8px rgb(44 62 80 / 20%)" }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-[#40566D] font-semibold">Document level Defects</h1>
        <button
          className="border border-[#5E48FC] text-[#5E48FC] rounded-lg text-sm cursor-pointer py-1 flex items-center px-1 shadow-md"
          onClick={() => setShowDocLabelDefect((prev) => !prev)}
        >
          {showDocLabelDefect ? "Collapse All" : "View All"}
          <div className="w-5">
            {showDocLabelDefect ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </div>
        </button>
      </div>

      {showDocLabelDefect ? (
        <div>
          <div className="flex items-center justify-between mt-2">
            <p></p>
            <button
              onClick={() => setShowAddDefects((prev) => !prev)}
              className="border flex items-center border-[#5E48FC] text-[#5E48FC] text-sm rounded-lg px-2 py-1"
            >
              {addDefects ? "Cancel" : "Add Defects"}
            </button>
          </div>
          <div className="flex mt-2 flex-wrap">
            {doc_level_defects.map((defects, index) => {
              const defectText = Object.values(defects)[0][0];
              if (
                defectText === "Source of information disclosed" ||
                defectText === "Source of information NOT disclosed"
              ) {
                return null;
              }
              const isDefect = defectStrings.includes(defectText);

              return (
                <div
                  className={`px-3 py-1 m-1 text-sm rounded-3xl text-white ${
                    isDefect ? "bg-red-500" : "bg-blue-500"
                  }`}
                  key={index}
                >
                  {defectText}
                  <button
                    className="ml-2"
                    onClick={() => handleRemoveDefect(defectText)}
                  >
                    <FontAwesomeIcon icon={faXmark} color="white" />
                  </button>
                </div>
              );
            })}
          </div>
          {addDefects && (
            <div className="py-2">
              <div className="flex justify-end">
                <select
                  value=""
                  onChange={(e) => handleAddDefect(e.target.value)}
                  className="border text-[#40566D] text-sm py-1  rounded focus:outline-none"
                >
                  <option value="" disabled>
                    Select a defect to add
                  </option>
                  {availabelOptions?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="text-[#40566D] text-sm font-semibold">
                  Remarks:
                </label>
                <textarea
                  name="remarks"
                  value={updateData?.remarks || ""}
                  onChange={handleRemarksChange}
                  className="border font-normal text-[#40566D] text-sm rounded p-2 focus:outline-none w-full"
                  rows="2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="border px-3 py-1 rounded-lg shadow-md text-sm bg-[#5E48FC] text-white"
                  onClick={showToast}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DoclabelDefect;
